// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/DataQueues/EventDialogServiceTimeSidebar/InputSwitchWrapper.js

import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

const InputSwitchWrapper = ({ paymentMethodGroup1, handlePaymentMethodChangeGroup1 }) => {
    return (
        <>
            <div className="input-switch-wrapper">
                <InputSwitch checked={paymentMethodGroup1 === 'visa'} onChange={() => handlePaymentMethodChangeGroup1('visa')} disabled />
            </div>
            <div className="input-switch-wrapper">
                <InputSwitch checked={paymentMethodGroup1 === 'payCash'} onChange={() => handlePaymentMethodChangeGroup1('payCash')} />
            </div>
            <div className="input-switch-wrapper">
                <InputSwitch checked={paymentMethodGroup1 === 'qrCode'} onChange={() => handlePaymentMethodChangeGroup1('qrCode')} />
            </div>
        </>

    );
};

export default InputSwitchWrapper;
