import React, { useState, useEffect } from 'react';
import { fetchCRMSystemInfo } from '../api/api';
import '../css/Sidebar.css'; 

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('dashboard');
  const [openDropdown, setOpenDropdown] = useState(null);  // To manage dropdown state

  const [userProfile, setUserProfile] = useState({
    name: '',
    role: '',
    image: ''
  });

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const crmSystemInfo = await fetchCRMSystemInfo();
        setUserProfile({
          name: crmSystemInfo['company-name'],
          role: crmSystemInfo['name-system'],
          image: crmSystemInfo['img-profile'],
        });
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    getUserProfile(); 
  }, []);

  // Updated menuItems with subItems for Reports
  const menuItems = [
    { name: 'Dashboard', icon: 'fa-tachometer-alt', path: '/Dashboard' },
    { name: 'Product', icon: 'fa-spa', path: '/services' },   
    { name: 'Type Product', icon: 'fa-layer-group', path: '/typeservices' },
    { name: 'Reviews', icon: 'fa-comment-dots', path: '/reviews', customClass: 'menu-item-reviews' },  // Add customClass instead of className
    { name: 'Order', icon: 'fa-calendar-check', path: '/booking' },
    { name: 'Payment', icon: 'fa-credit-card', path: '/payment' },
    { name: 'Queues', icon: 'fa-people-group', path: '/queues' },   
    {
      name: 'Reports', icon: 'fa-file-invoice', path: '#', subItems: [
        { name: 'Order', icon: 'fa-file-invoice', path: '/report/booking' },
      ]
    },
    { name: 'Staff', icon: 'fa-users', path: '/Member' },
    { name: 'Logout', icon: 'fa-sign-out-alt', path: '/logout' }
  ];

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    setOpenDropdown(null);  // Close any open dropdown when navigating
  };

  const toggleDropdown = (itemName) => {
    if (openDropdown === itemName) {
      setOpenDropdown(null); // Close the dropdown if clicked again
    } else {
      setOpenDropdown(itemName); // Open the dropdown
    }
  };

  const renderSubItems = (subItems) => {
    return (
      <ul className="submenu">
        {subItems.map((subItem) => (
          <li key={subItem.name} className="submenu-item">
            <a
              href={subItem.path}
              className="menu-item"
              onClick={() => handleMenuClick(subItem.name)}
            >
              <i className={`fas ${subItem.icon}`}></i> {subItem.name}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <aside className="sidebar">
      {/* User Profile */}
      <div className="user-profile">
        <img
          src={userProfile.image}
          alt={userProfile.name}
          className="user-image"
        />
        <div className="user-info text-center">
          <h5 className="user-name">{userProfile.name}</h5>
        </div>
      </div>

      <nav className="menu">
        {menuItems.map(item => (
          <div key={item.name} className={item.customClass ? item.customClass : ''}>
            <a
              href={item.path}
              className={`menu-item ${activeMenu === item.name ? 'active' : ''}`}
              onClick={() => item.subItems ? toggleDropdown(item.name) : handleMenuClick(item.name)}
            >
              <i className={`fas ${item.icon}`}></i> {item.name}
              {item.subItems && <i className="fas fa-chevron-down dropdown-icon"></i>}
            </a>
            {item.subItems && openDropdown === item.name && renderSubItems(item.subItems)}
          </div>
        ))}
      </nav>

      <footer className="sidebar-footer">
        <p>&copy; {new Date().getFullYear()} {userProfile.role}</p>
        <p>{userProfile.name}</p>
      </footer>
    </aside>
  );
};

export default Sidebar;
