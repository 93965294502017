///var/www/website2024/admin.siamweeds.com/myapp/src/components/GalleryForm.js

import React, { useState, useEffect } from 'react';
import { OrderList } from 'primereact/orderlist';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const GalleryForm = ({
  previewImages,
  handleImageRemove, // This should be updated to use the setState method to refresh the OrderList
  onImageOrderChange,
  handleImagesChange,
}) => {
  // You may want to create a new state hook to trigger a re-render
  const [refresh, setRefresh] = useState(false);
  const [images, setImages] = useState([]);


  useEffect(() => {
    console.log("Preview images received in GalleryForm:", previewImages);

    // Process previewImages to handle both database images and new uploads
    const processedImages = previewImages.map((img, index) => ({
      id: img.id || index,  // Assign index if id is not available to avoid NaN
      resizedFileURLs: img.resizedFileURLs || '',  // Fallback to empty string if no URLs are present
      isFromDatabase: img.isFromDatabase || false,
      url: img.url || '',  // Ensure url is an empty string if not present
    }));

    console.log("Processed Images:", processedImages);
    setImages(processedImages);
  }, [previewImages]);







  // Update the handleImageRemove function to use the new state
  const handleImageRemoveAndUpdate = (id) => {
    handleImageRemove(id);
    setRefresh(prev => !prev); // Toggle the refresh state to force re-render
  };

  const imageItemTemplate = (image, index) => {
    const imageUrl = image.url || image.resizedFileURLs || '';  // Fallback to an empty string if no URL is present
    const imageId = image.id || index;  // Use index if id is not available

    // Handle NaN values and ensure correct sequential numbering with a fallback value
    const displayIndex = !isNaN(index) && index !== undefined ? index + 1 : (imageId + 1);

    return (
      <div className="image-item" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <span>{displayIndex}</span> {/* Display the sequential index */}
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={`Preview ${displayIndex}`}
            style={{ width: '100px', height: 'auto' }}
          />
        ) : (
          <span>No Image Available</span>  // Display a message if there's no image URL
        )}
        <i
          className="pi pi-trash"
          style={{ cursor: 'pointer' }}
          onClick={() => handleImageRemove(imageId)}
        />
      </div>
    );
  };




  return (
    <div>
      <label htmlFor="galleryInput" className="form-label">Upload Images:</label>
      <input
        type="file"
        className="form-control"
        id="galleryInput"
        multiple
        onChange={handleImagesChange}
      />

      {images.length > 0 && (
        <OrderList
          value={images}  // Ensure images state is correctly set without duplication
          itemTemplate={imageItemTemplate}
          onChange={onImageOrderChange}
          listStyle={{ maxHeight: '700px', width: 'auto' }}
          dragdrop
        />
      )}


    </div>
  );
};

export default GalleryForm;
