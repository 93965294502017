// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/request_uuid.js
const requestUUID = async (table_id = "3") => {

    const user_ID = parseInt(localStorage.getItem('user_ID'), 10) || null;
    let ip_address = '192.168.1.0'; // Default IP address

    try {
        // Attempt to fetch the user's public IP address
        const ipResponse = await fetch('https://api64.ipify.org?format=json');
        if (ipResponse.ok) {
            const ipData = await ipResponse.json();
            ip_address = ipData.ip || '192.168.1.0';  // Use fetched IP or fallback to default
        } else {
            console.warn('Failed to fetch IP address, using default IP');
        }
    } catch (error) {
        console.warn('Error while fetching IP address, using default IP:', error);
    }


    const uuidRequestData = {
        table_id: table_id,
        user_id: user_ID, 
        ip_address: ip_address,
        timestamp: new Date().toISOString().replace('T', ' ').slice(0, 19),
        status: 1
    };

    const commonHeaders = {
        'Content-Type': 'application/json',
        // Ensure you have a valid token available in your application's state or storage
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    console.log("Request Data to be sent to API:", uuidRequestData);

    try {
        const response = await fetch('https://py-maracar.afaa.website/generate/api/uuid_create', {
            method: 'POST',
            headers: commonHeaders,
            body: JSON.stringify(uuidRequestData),
        });

        console.log("API Response Status:", response.status);

        if (!response.ok) {
            // If the response is not ok, throw an error before attempting to parse JSON
            throw new Error(`Failed to fetch UUID, status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Response Data from API:", data);
        return data.uuid; // Return UUID
    } catch (error) {
        console.error('Error fetching UUID:', error);
        throw error; // Rethrow error to be handled by caller
    }
};

export default requestUUID;