///var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/pages/Reviews.js

import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import ReviewsForm from '../components/ReviewsForm';
import ReviewsTable from '../components/ReviewsTable';
import { Button } from 'primereact/button';
import { API_REVIEWS } from '../api/api';
import '../css/ReviewsForm.scss';


const Reviews = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [currentReview, setCurrentReview] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch user_ID from localStorage
  const user_ID = localStorage.getItem('user_ID'); // Ensure this is available



  const handleTableRefresh = useCallback(() => {
    fetchReviewsData(); // Directly call fetchMemberData to refresh
  }, [user_ID]);



  // Fetch reviews data
  const fetchReviewsData = async () => {
    try {
      const response = await fetch(API_REVIEWS.VIEW);
      const data = await response.json();
      setReviewsData(data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  useEffect(() => {
    fetchReviewsData();
  }, []);

  // Handle edit
  const handleEditReview = (reviewId) => {
    const reviewToEdit = reviewsData.find((review) => review.review_id === reviewId);
    setCurrentReview(reviewToEdit);
    setFormMode('edit');
    setIsFormVisible(true);
  };

  const handleDeleteReview = async (reviewId) => {
    // Use the DELETE endpoint from API_REVIEWS in api.js
    const url = API_REVIEWS.DELETE;
  
    // Prepare the payload to include review_ids as an array
    const payload = {
      review_ids: [reviewId]  // Send reviewId as an array with one or more IDs
    };
  
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`  // Ensure authorization is included
        },
        body: JSON.stringify(payload),  // Convert payload to JSON
      });
  
      if (response.ok) {
        fetchReviewsData(); // Refresh the reviews data after successful deletion
      } else {
        throw new Error('Failed to delete review');
      }
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };
  

  // Handle add
  const handleAddReview = () => {
    setCurrentReview(null);
    setFormMode('add');
    setIsFormVisible(true);
  };


  // Helper function to format updated_at as "YYYY-MM-DDTHH:MM:SS"
  const formatDateWithoutMilliseconds = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };



  const handleSaveReview = async (data) => {
    const url = formMode === 'edit' ? API_REVIEWS.EDIT : API_REVIEWS.CREATE;

    // Additional fields required by the API
    const user_id = localStorage.getItem('user_ID');
    const table_data = 4; // Fixed value
    const updated_at = formatDateWithoutMilliseconds(new Date()); // Format date
    const IP = await fetch("https://api.ipify.org?format=json")
      .then(res => res.json())
      .then(data => data.ip); // Fetch IP address

    // Prepare the payload in the required format (as an array)
    const payload = [
      {
        user_id: user_id,
        table_data: table_data,
        review_typeID: data.review_typeID,
        rating: data.rating,
        topic_title: data.topic_title,
        comment: data.CommentName,
        updated_at: updated_at,
        IP: IP,
        status: data.status
      }
    ];

    // If mode is 'edit', include review_id in the payload
    if (formMode === 'edit') {
      payload[0].review_id = currentReview.review_id; // Add review_id for edit mode
    }

    try {
      const response = await fetch(url, {
        method: formMode === 'edit' ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        fetchReviewsData(); // Refresh reviews data
        setIsFormVisible(false);
      } else {
        console.error('Failed to save review:', result);
        throw new Error('Failed to save review');
      }
    } catch (error) {
      console.error('Error saving review:', error);
    }
  };




  const handleCancelEdit = () => {
    setIsFormVisible(false);
  };


  return (
    <div className="dashboard">
      <Sidebar />

      <div className="dashboard-main">

        <Header
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onBack={isFormVisible ? handleCancelEdit : undefined} // Correct the function here
          refreshData={handleTableRefresh}
        />


        <div className="member-table-container" style={{ marginTop: '2%' }}>

          {!isFormVisible && (
            <Button
              label="Add New Review"
              icon="pi pi-plus"
              className="p-button-success button-add-1"
              onClick={handleAddReview}
            />
          )}

          {isFormVisible ? (
            <ReviewsForm
              reviewData={currentReview}
              mode={formMode}
              onCancel={() => setIsFormVisible(false)}
              onSave={handleSaveReview}
              user_ID={user_ID}
            />
          ) : (
            <ReviewsTable
              reviewsData={reviewsData}
              onEditReview={handleEditReview}
              onDeleteReview={handleDeleteReview}
            />
          )}
        </div>

      </div>
    </div>
  );
};

export default Reviews;
