// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/ServicesForm.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faTimes } from '@fortawesome/free-solid-svg-icons';

import TextEditor from './editor_textarea';
import requestUUID from './request_uuid';
import ToastNotification from './ToastNotification';
import PriceOptions from './PriceOptions';
import ImageForm from './ImageForm';
import GalleryForm from './GalleryForm';
import { API_URLS, API_ENDPOINTS } from '../api/api.js';


const ServicesForm = ({ serviceData, mode, onCancel, onSave }) => {
  const [uuid, setUuid] = useState('');
  const user_ID = parseInt(localStorage.getItem('user_ID')) || null;

  // Function to handle changes in TextEditor and update the state
  const handleDescriptionChange = (value) => {
    //setFormData({ ...formData, Description: value });
    setFormData(formData => ({ ...formData, Description: value }));
  };

  const handleInfoChange = (value) => {
    //setFormData({ ...formData, Info: value });
    setFormData(formData => ({ ...formData, Info: value }));
  };

  const [formData, setFormData] = useState({
    ServiceID: mode === 'edit' ? serviceData.ServiceID : undefined,
    ServiceName: serviceData?.ServiceName || '',
    Description: serviceData?.Description || '',
    ImageURL: serviceData?.ImageURL || '',
    CategoryID: serviceData?.CategoryID || '',
    Info: serviceData?.Info || '',
    status: serviceData?.status || '',
  });

  // State to manage the dynamic price options
  const [priceOptions, setPriceOptions] = useState([
    {
      PriceType: '',       // Initializes an empty string for PriceType in the price option.
      PriceValue: 0,       // Initializes PriceValue as 0 for the price option.
      currency: 'THB',     // Sets 'THB' as the default currency for the price option.
      symbol: '฿',         // Sets the symbol '€' for the currency.
      status: '1',          // Initializes an empty string for status in the price option.
      details: 'null',         // Initializes an empty string for details in the price option.
      TableData_ID: '3',    // Initializes an empty string for TableData_ID in the price option.
      priceConn_dataID: formData.ServiceID || '0', // Initializes an empty string for priceConn_dataID in the price option.
      uuid: uuid,
      user_ID: parseInt(user_ID),
    },
  ]);

  const handlePriceOptionChange = (index, e) => {
    const { name, value } = e.target;
    const user_ID = parseInt(localStorage.getItem('user_ID'), 10);  // Ensure user_ID is fetched from localStorage
    const newPriceOptions = priceOptions.map((option, i) => {
      if (i === index) {
        return {
          ...option,
          [name]: value,
          symbol: option.symbol || 'THB',
          details: option.details || '',  // Ensure details is always a string
          TableData_ID: option.TableData_ID || '3',  // Ensure TableData_ID is set to 3
          uuid: option.uuid || uuid,
          user_ID: user_ID,  // Ensure user_ID is passed in each price option
        };
      }
      return option;
    });
    setPriceOptions(newPriceOptions);
  };



  useEffect(() => {
    if (priceOptions.length === 0) {
      setPriceOptions([{
        PriceType: '',        // Ensure text fields have a default value
        PriceValue: 0,        // Ensure number fields have a default value
        currency: 'THB',      // Set a default currency value
        symbol: '฿',
        status: '1',
        details: '',
        unit_large_name: '',   // Ensure large unit has a string default
        unit_small_name: '',   // Ensure small unit has a string default
        conversion_factor_to_small_unit: 1,
        store_quantity: 0,    // Ensure quantity fields have a numeric default
        store_unit_type: 1,
        warehouse_quantity: 0,
        warehouse_unit_type: 1,
        online_quantity: 0,
        online_unit_type: 1,
      }]);
    }
  }, [priceOptions, setPriceOptions]);



  // New state for toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('primary'); // You can have different variants like 'success', 'warning', etc.

  const addPriceOption = async () => {
    // Assume all options should have PriceType and PriceValue filled
    const isAnyOptionEmpty = priceOptions.some(option => option.PriceType === '' || option.PriceValue === 0);

    // Retrieve user_ID from localStorage or state
    const user_ID = localStorage.getItem('user_ID') || 'defaultUserID';
    const serviceID = formData.ServiceID || 'defaultServiceID';

    // Fetch UUID dynamically if it is not already set
    if (!uuid) {
      console.log("UUID is not available, fetching a new one...");
      try {
        const fetchedUuid = await requestUUID();
        if (!fetchedUuid) {
          console.error("Failed to fetch UUID. Cannot add a new price option.");
          setShowToast(true);
          setToastMessage('Failed to generate UUID. Please try again.');
          setToastVariant('danger');
          return;
        }
        setUuid(fetchedUuid); // Update state with the new UUID
        formData.uuid = fetchedUuid;
      } catch (error) {
        console.error("Error fetching UUID:", error);
        setShowToast(true);
        setToastMessage('Error fetching UUID. Please try again.');
        setToastVariant('danger');
        return;
      }
    }

    if (!isAnyOptionEmpty) {
      // Log the price options before the addition
      console.log('Price options before addition:', priceOptions);
      console.log('UUID for price option:', uuid);

      // Add a new price option with the fetched or existing UUID
      setPriceOptions(prevOptions => [
        {
          PriceType: '',
          PriceValue: 0,
          currency: 'THB',
          symbol: '฿',
          status: '1',
          details: 'null',
          TableData_ID: '3',
          priceConn_dataID: formData.ServiceID || '0',
          uuid: uuid, // Use the current or newly fetched UUID
          PriceID: 0,
          user_ID: user_ID,
        },
        ...prevOptions,
      ]);
      console.log('A new price option has been added. Price options after addition:', priceOptions);

      setToastMessage('A new price option is ready to be added.');
      setToastVariant('success'); // or any other variant you wish to use
      setShowToast(true);
      console.log("Adding new price option:", isAnyOptionEmpty);
    } else {
      // Prevent adding new option and show warning toast
      console.log("Please fill in the last price option before adding a new one.");
      setToastMessage('Please fill in all fields of the existing price options before adding a new one.');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const removePriceOption = (index) => {
    const updatedPriceOptions = [...priceOptions];
    updatedPriceOptions.splice(index, 1); // ลบ price option ที่ต้องการออกจากอาร์เรย์
    // อัปเดต state ด้วยรายการ price options ใหม่ที่ไม่รวม price option ที่ถูกลบ
    setPriceOptions(updatedPriceOptions);
  };


  const [statusOptions, setStatusOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // State ใหม่สำหรับการติดตามการบันทึก
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(serviceData?.ImageURL || '');

  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [triggerAddOption, setTriggerAddOption] = useState(false);

  const [priceData, setPriceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };

  const handleImagesChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);

    const newPreviewImages = files.map(file => {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({ url: reader.result, isFromDatabase: false });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(newPreviewImages).then(images => {
      setPreviewImages(prevImages => [...prevImages, ...images]);
    });
  };




  const onImageOrderChange = (e) => {
    setPreviewImages(e.value); // Update the order of preview images
  };


  const imageItemTemplate = (image, index) => {
    return (
      <div className="image-item">
        <img src={image.url || image.resizedFileURLs} alt={`Preview ${index}`} />
      </div>
    );
  };




  const uploadImages = async () => {
    const formData = new FormData();
    // รวมการเลือกรูปภาพเดี่ยวและหลายรูปภาพเข้าด้วยกัน
    if (selectedImage) {
      formData.append('uploadFile[]', selectedImage);
    }

    selectedImages.forEach(image => formData.append('uploadFile[]', image));

    // หากไม่มีรูปภาพเพื่ออัพโหลด
    if (!selectedImage && selectedImages.length === 0) {
      return;
    }

    const response = await fetch(API_URLS.IMAGE_UPLOAD, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload image(s).');
    }

    const responseData = await response.json();

    // พิมพ์การตอบกลับ (response)
    console.log(responseData);

    return responseData;
  };

  // อัปเดต formData เมื่อ serviceData มีการเปลี่ยนแปลง
  useEffect(() => {

    // Inside useEffect in ServicesForm.js

    const fetchImages = async () => {
      const serviceID = formData.ServiceID || '0';
      try {
        console.log("Fetching images for serviceID:", serviceID);
        console.log("Fetching images for user_ID:", user_ID);

        // Use a single fetch request instead of Promise.all
        const response = await fetch(`https://py-maracar.afaa.website/img/api_env_3/view?TableData_ID=${serviceID}&user_ID=${user_ID}`);

        if (!response.ok) {
          throw new Error(`Error fetching images: ${response.statusText}`);
        }

        // Process response data and set state
        const images = await response.json();
        console.log("Images data:", images);

        const formattedImages = images.map(img => ({ ...img, isFromDatabase: true }));
        setPreviewImages(formattedImages.length > 0 ? formattedImages : []);
      } catch (error) {
        console.error("Error fetching image data:", error);
        setToastMessage('Error fetching image data.');
        setToastVariant('danger');
        setShowToast(true);
        setPreviewImages([]); // Clear preview images on error
      }
    };

    // Call fetchImages function within useEffect
    fetchImages();

    // ดึงข้อมูลสถานะ
    const fetchStatusOptions = async () => {
      const response = await fetch(API_URLS.STATUS_LIST);
      const data = await response.json();
      setStatusOptions(data);
    };

    // ดึงข้อมูลประเภทบริการ
    const fetchCategoryOptions = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.SERVICES_VIEW_TYPE}?user_ID=${user_ID}`, {
          method: 'GET',
          headers: commonHeaders,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        //console.log('Fetched category data:', data); // Log the fetched data
        setCategoryOptions(data);
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };



    if (serviceData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ServiceID: serviceData.ServiceID, // อัปเดต ServiceID สำหรับการแก้ไข
        ServiceName: serviceData.ServiceName,
        Description: serviceData.Description,
        ImageURL: serviceData.ImageURL,
        CategoryID: serviceData.CategoryID,
        Info: serviceData.Info,
        status: serviceData.status,
      }));
    }

    if (triggerAddOption) {
      addPriceOption();
      setTriggerAddOption(false); // รีเซ็ต trigger หลังจากเพิ่มตัวเลือกการกำหนดราคา
    }


    const fetchPriceData = async () => {
      setIsLoading(true);

      const user_ID = parseInt(localStorage.getItem('user_ID')) || null;
      const serviceID = formData.ServiceID || null;

      if (!serviceID || !user_ID) {
        console.error('ServiceID or User_ID is not defined.');
        setIsLoading(false);
        return;
      }

      try {
        const url = API_URLS.PRICE_DATA(serviceID, user_ID);
        const response = await fetch(url, {
          method: 'GET',
          headers: commonHeaders,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch price data');
        }

        const jsonData = await response.json();

        const transformedData = jsonData.prices.map(price => ({
          PriceID: price.PriceID,
          PriceType: price.PriceType,
          PriceValue: price.PriceValue,
          currency: price.currency,
          status: price.status.toString(),
          unit_large_name: price.unit_large_name,
          unit_small_name: price.unit_small_name,
          conversion_factor_to_small_unit: price.conversion_factor_to_small_unit,
          store_quantity: price.store_quantity,
          store_unit_type: price.store_unit_type,
          warehouse_quantity: price.warehouse_quantity,
          warehouse_unit_type: price.warehouse_unit_type,
          online_quantity: price.online_quantity,
          online_unit_type: price.online_unit_type,
          details: price.details || "",
          TableData_ID: price.TableData_ID,
          priceConn_dataID: price.priceConn_dataID,
          uuid: price.uuid,
          symbol: price.symbol,
          user_ID: user_ID,
        }));

        setPriceOptions(transformedData);
      } catch (error) {
        console.error("Error fetching price data:", error);
      } finally {
        setIsLoading(false);
      }
    };



    if (priceData) {
      // มีข้อมูลราคา
      console.log('มีข้อมูลราคา:', priceData);
    } else {
      // ไม่มีข้อมูลราคา
      console.log('ไม่มีข้อมูลราคา');
    }


    fetchStatusOptions();
    fetchCategoryOptions();
    fetchPriceData();
  }, [serviceData, triggerAddOption, user_ID]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageRemove = async (imageId) => {
    const response = await fetch(API_URLS.IMAGE_DELETE, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: imageId, user_ID })
    });

    if (response.ok) {
      // Remove the image from the state if the deletion was successful
      setPreviewImages(prevImages => prevImages.filter(img => img.id !== imageId));
    } else {
      console.error('Failed to delete the image.');
    }
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewImage(fileReader.result);  // This should update the preview correctly
      };
      fileReader.readAsDataURL(file);
    }
  };


  const updatedPriceOptions = priceOptions.map(option => {
    return { ...option, uuid: uuid || '' }; // Ensure uuid is assigned, replace '' with a fallback if needed
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting form submission...");
    setIsSaving(true);

    try {
      const user_ID = parseInt(localStorage.getItem('user_ID'), 10);

      // Fetch UUID only when handleSubmit is called and UUID is not yet set
      if (!uuid) {
        console.log("UUID is not available, fetching a new one...");
        const fetchedUuid = await requestUUID();
        if (!fetchedUuid) {
          console.error("Failed to fetch UUID. Submission aborted.");
          setShowToast(true);
          setToastMessage('Failed to generate UUID. Please try again.');
          setToastVariant('danger');
          setIsSaving(false);
          return;
        }
        setUuid(fetchedUuid);
        formData.uuid = fetchedUuid;
      } else {
        formData.uuid = uuid;
      }

      formData.user_ID = user_ID;

      // Ensure valid price options are still calculated
      const validPriceOptions = priceOptions
        .filter(option => option.PriceType.trim() !== '' && option.PriceValue > 0 && option.currency && option.status)
        .map(option => ({
          ...option,
          PriceValue: parseInt(option.PriceValue, 10),
          details: option.details || '',
          TableData_ID: '3',  // Ensure TableData_ID is set to 3
          priceConn_dataID: formData.ServiceID || option.priceConn_dataID,
          user_ID: user_ID,  // Ensure user_ID is passed to each price option
        }));

      if (validPriceOptions.length < priceOptions.length) {
        console.log('Some price options are incomplete and will not be submitted.');
        setShowToast(true);
        setToastMessage('Incomplete price options detected. Only complete options will be submitted.');
        setToastVariant('warning');
      }

      // Always proceed with image upload regardless of price options validation
      const imageResponse = await uploadImages();
      console.log('Image upload response:', JSON.stringify(imageResponse, null, 2));

      if (imageResponse) {
        const imageUploadDataArray = imageResponse.fileNames.map((fileName, index) => ({
          uuid: formData.uuid, // Use the updated formData.uuid
          user_ID: user_ID,
          tabledataID: formData.ServiceID ? Number(formData.ServiceID) : undefined,
          fileNames: [fileName],
          originalFileURLs: [imageResponse.originalFileURLs[index]],
          resizedFileURLs: [imageResponse.resizedFileURLs[index]],
        }));

        const serverResponse = await fetch('https://py-maracar.afaa.website/img/api_env_3/upload', {
          method: 'POST',
          headers: commonHeaders,
          body: JSON.stringify(imageUploadDataArray),
        });

        if (!serverResponse.ok) {
          const errorText = await serverResponse.text();
          console.error('Server Response:', errorText);
          throw new Error('Failed to save image data to the database.');
        }
        console.log('Image data saved successfully.');
        formData.ImageURL = imageUploadDataArray[0].resizedFileURLs[0]; // Update form data with image URL
      }

      // Filter out price options with PriceID not equal to 0
      const filteredPriceOptions = validPriceOptions.filter(option => option.PriceID !== 0);

      if (filteredPriceOptions.length === 0) {
        console.error("Error: No valid price options available for submission.");
        setShowToast(true);
        setToastMessage('No valid price options available for submission.');
        setToastVariant('danger');
        setIsSaving(false);
        return;
      }

      // Separate price options for update and create operations
      const priceOptionsToUpdate = validPriceOptions.filter(option => option.PriceID > 0);
      const priceOptionsToCreate = validPriceOptions.filter(option => !option.PriceID || option.PriceID <= 0);

      // Submit Updates (if any)
      if (priceOptionsToUpdate.length > 0) {
        const updatePayload = priceOptionsToUpdate.map(option => ({
          ...option,
          PriceValue: parseInt(option.PriceValue, 10), // Ensure PriceValue is an integer
        }));

        const updateResponse = await fetch(API_URLS.PRICE_UPDATE, {
          method: 'PUT',
          headers: commonHeaders,
          body: JSON.stringify(updatePayload),
        });

        if (!updateResponse.ok) throw new Error('Failed to update price data.');
        console.log('Price data updated successfully.');
      }

      // Submit Creations (if any)
      if (priceOptionsToCreate.length > 0) {
        const createPayload = {
          Prices: priceOptionsToCreate.map(option => ({
            ...option,
            PriceValue: parseInt(option.PriceValue, 10), // Ensure PriceValue is an integer
          })),
        };

        const createResponse = await fetch(API_URLS.PRICE_CREATE, {
          method: 'POST',
          headers: commonHeaders,
          body: JSON.stringify(createPayload),
        });

        if (!createResponse.ok) throw new Error('Failed to create price data.');
        console.log('Price data created successfully.');
      }

      // Final form submission and state update
      console.log('Form data saved successfully:', formData);
      onSave(formData); // Save or update the service with the current form data
      setIsSaving(false); // Reset saving state

    } catch (error) {
      console.error("Error during form submission:", error);
      setShowToast(true);
      setToastMessage('Error during form submission. Please try again.');
      setToastVariant('danger');
      setIsSaving(false);
    }
  };


  return (
    <div className="">
      {/* คอมโพเนนต์ ToastNotification */}
      <ToastNotification
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
        toastVariant={toastVariant}
      />

      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Left column for form inputs */}
            <div className="col-md-8">
              {/* Status */}

              {/* Status */}
              <div className="mb-3">
                <label htmlFor="status" className="form-label">Status:</label>
                <select
                  id="status"
                  name="status"
                  className="form-control"
                  value={formData.status || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a Status</option>
                  {statusOptions.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>


              {/* Category ID */}
              <div className="mb-3">
                <label htmlFor="CategoryID" className="form-label">Category:</label>
                <select
                  id="CategoryID"
                  name="CategoryID"
                  className="form-control"
                  value={formData.CategoryID || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a Category</option>
                  {categoryOptions.map(option => (
                    <option key={option.ServiceTypes_ID} value={option.ServiceTypes_ID}>
                      {option.ServiceTypes_Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Service Name */}
              <div className="mb-3">
                <label htmlFor="ServiceName" className="form-label">Service Name:</label>
                <input
                  type="text"
                  id="ServiceName"
                  name="ServiceName"
                  className="form-control"
                  value={formData.ServiceName || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Price Details Table */}

              {/* Within ServicesForm.js component's return statement */}
              <PriceOptions
                priceOptions={priceOptions}
                priceData={priceData}
                handlePriceOptionChange={handlePriceOptionChange}
                addPriceOption={addPriceOption}
                setPriceOptions={setPriceOptions}
                removePriceOption={removePriceOption}
                statusOptions={statusOptions} // Make sure you've fetched and stored the status options in state
              />

              {/* Description */}
              <div className="mb-3">
                <label htmlFor="Description" className="form-label">Description:</label>

                <TextEditor
                  value={formData.Description}
                  onContentChange={handleDescriptionChange}
                />
              </div>

              {/* Additional Info */}
              <div className="mb-3">
                <label htmlFor="Info" className="form-label">Additional Info:</label>
                <TextEditor
                  value={formData.Info}
                  onContentChange={handleInfoChange}
                />
              </div>

            </div>

            {/* Right column for image and gallery */}
            <div className="col-md-4">
              {isSaving && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {/* Submit and Cancel buttons */}
              <div className="d-grid gap-2 d-md-flex justify-content-md-end pa-top-10">

                <button type="button" onClick={onCancel} className="btn btn-light">
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>

                <button type="submit" className="btn btn-dark">
                  <FontAwesomeIcon icon={faFloppyDisk} /> {mode === 'add' ? 'Add' : 'Update'} Service
                </button>

              </div>

              <ImageForm
                selectedImage={selectedImage}
                previewImage={previewImage}
                handleImageChange={handleImageChange}
              />


              {/* Image URL */}
              <div className="mb-3">
                <label htmlFor="ImageURL" className="form-label">Image URL:</label>
                <input
                  type="text"
                  id="ImageURL"
                  name="ImageURL"
                  className="form-control"
                  value={formData.ImageURL || ''}
                  onChange={handleChange}
                />
              </div>

              <GalleryForm
                selectedImages={selectedImages}
                previewImages={previewImages}
                handleImageRemove={handleImageRemove}
                handleImagesChange={handleImagesChange}
                onImageOrderChange={onImageOrderChange}
                imageItemTemplate={imageItemTemplate}
              />

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ServicesForm;
