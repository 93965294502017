// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/redux/slices/employeeSlice.js
const SET_SELECTED_EMPLOYEE = 'SET_SELECTED_EMPLOYEE';

export const setSelectedEmployee = (employeeID) => {
  // Store employeeID in local storage only if it is defined and greater than 0
  if (employeeID && employeeID > 0) {
    localStorage.setItem('EventselectedEmployee', employeeID);
  }
  return {
    type: SET_SELECTED_EMPLOYEE,
    payload: employeeID,
  };
};

const initialState = {
  selectedEmployee: null,
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    default:
      return state;
  }
};
