// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/EventContent.js
import React from 'react';

const EventContent = ({ eventInfo }) => {
  if (!eventInfo || !eventInfo.event || !eventInfo.event.extendedProps) return null;

  const { extendedProps } = eventInfo.event;
  const priceStyle = extendedProps.serviceStatus === '1' ? 'color:green;' : 'color:red;';

  const serviceName = extendedProps.serviceName || eventInfo.event.title || 'N/A';
  const truncatedServiceName = serviceName.length > 17 ? serviceName.substring(0, 17) + '...' : serviceName;


  const htmlContent = `
  <div style="display:flex; flex-direction:column; align-items:start; font-size: small;">
    <div>${truncatedServiceName}</div>
    <div style="display: flex; align-items: center;">
      <i class="pi pi-clock" style="margin-right:4px;"></i>${extendedProps.time} 
      <span style="${priceStyle}; margin-left: 8px;"><i class="pi pi-money-bill" style="margin-right:4px;"></i>${extendedProps.price}</span>
    </div>
  </div>
`;

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
  );
};

export default EventContent;
