// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/MemberTable.js

import React, { useState, useEffect } from 'react';
import useFetchStatuses from './useFetchStatuses'; // import the custom hook
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsersGear, faPeopleArrows, faKey } from '@fortawesome/free-solid-svg-icons';



const MemberTable = ({ memberData, onEditMember, token }) => {
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState({}); // New state to track selected positions
  const [selectedStatuses, setSelectedStatuses] = useState({}); // New state for tracking selected statuses
  const { statuses, isLoading, error } = useFetchStatuses(token); // use the custom hook

  useEffect(() => {
    // Fetch positions when the component mounts
    const fetchPositions = async () => {
      try {
        const response = await fetch('https://py-maracar.afaa.website/register/api/position_list');
        const data = await response.json();
        setPositions(data);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    };
    fetchPositions();

    //initialPositions
    //Initialize selectedPositions with current positions of members
    const initialPositions = memberData.reduce((acc, member) => ({
      ...acc,
      [member.user_ID]: member.position_ID, // Assuming memberData includes position_ID
    }), {});
    setSelectedPositions(initialPositions);

    // initialStatuses
    const initialStatuses = memberData.reduce((acc, member) => ({
      ...acc,
      [member.user_ID]: member.status_ID,
    }), {});
    setSelectedStatuses(initialStatuses);


  }, [memberData]);


  const renderStatusDropdown = (userId) => {
    const currentStatusId = selectedStatuses[userId];
    const currentStatusName = statuses.find(s => s.id === currentStatusId)?.name || "Select Status";


    return (
      <div className="dropdown">
        <button className="btn btn-warning dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <FontAwesomeIcon icon={faPeopleArrows} /> {currentStatusName}
        </button>
        <ul className="dropdown-menu">
          {statuses.map((status) => (
            <li key={status.id}>
              <a className="dropdown-item" href="#" onClick={(event) => {
                event.preventDefault();
                handleStatusUpdate(userId, status.id);
              }}>
                {status.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleRoleUpdate = async (userId, newPositionId) => {
    // Implement the API call to update role
    try {
      const response = await fetch(`https://py-maracar.afaa.website/register/api/position_set?user_ID=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // ใช้โทเค็นที่นี่
        },
        body: JSON.stringify({ position_ID: newPositionId }),
      });


      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update position');
      }

      // Handle success (e.g., show a message or update local state)

      // Update selected position for the user
      setSelectedPositions(prev => ({ ...prev, [userId]: newPositionId }));


    } catch (error) {
      console.error('Error updating position:', error);
      // Handle error (e.g., show error message)
    }
  };

  const renderPositionDropdown = (userId) => {
    const selectedPositionId = selectedPositions[userId];
    const currentPosName = positions.find(p => p.position_ID === selectedPositionId)?.position_Name || "Select Position";

    return (
      <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <FontAwesomeIcon icon={faUsersGear} /> {currentPosName}
        </button>
        <ul className="dropdown-menu">
          {positions.map((position) => (
            <li key={position.position_ID}>
              <a className="dropdown-item" href="#" onClick={(event) => {
                event.preventDefault();
                handleRoleUpdate(userId, position.position_ID);
              }}>
                {position.position_Name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleStatusUpdate = async (userId, newStatusId) => {
    try {
      const response = await fetch(`https://py-maracar.afaa.website/register/api/update_status?user_ID=${userId}&table_name=user&id_field_name=user_ID&record_id=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ status_ID: newStatusId }),
      });

      console.log('handleStatusUpdate:', response);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update status');
      }

      // Handle success here
    } catch (error) {
      console.error('Error updating status:', error);
      // Handle error here
    }

    // Update selected status for the user
    setSelectedStatuses(prev => ({ ...prev, [userId]: newStatusId }));
  };

  const navigate = useNavigate(); // สร้าง instance ของ useNavigate

  const handleChangePassword = (userId) => {
    navigate(`/change-password?userId=${userId}`);
  };


  const getRowStyle = (positionId) => {
    switch (positionId) {
      case 1:
        return { backgroundColor: '#aeeaf4' };
      case 2:
        return { backgroundColor: '#fde7e4' };
      default:
        return { backgroundColor: '#e9f8fb' };
    }
  };
  


  return (


    <div className="table-responsive "> {/* Wrapper สำหรับการตอบสนอง */}
      <table className="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Sur Name</th>
            <th>Email</th>
            <th>Telephone</th>
            <th>Birthday</th>
            <th>Contact</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody >
          {memberData.map((member, index) => {
          const rowStyle = getRowStyle(selectedPositions[member.user_ID]);
            //console.log(`Member ID: ${member.user_ID}, Position ID: ${member.position_ID}`);
            return (
              <tr key={index} >
                <td  style={rowStyle}>{member.first_name}</td>
                <td>{member.sur_name}</td>
                <td>{member.email}</td>
                <td>{member.telephone}</td>
                <td>{new Date(member.birthday).toLocaleDateString()}</td>
                <td>{member.contact}</td>


                <td style={rowStyle}>
                  <div className="d-flex align-items-center">
                    <button className="btn btn-primary me-2 btn-sm" onClick={() => onEditMember(member)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <div className="me-2">
                      {renderPositionDropdown(member.user_ID)}
                    </div>
                    <div className="me-2">
                      {renderStatusDropdown(member.user_ID)}
                    </div>
                    <button className="btn btn-info btn-sm" onClick={() => handleChangePassword(member.user_ID)}>
                      <FontAwesomeIcon icon={faKey} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

  );
};

export default MemberTable;
