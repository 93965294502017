// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/EventDialogServiceTimeSidebar/PaymentDetails.js

import React from 'react';
import { InputText } from 'primereact/inputtext';
import { QRCodeCanvas } from 'qrcode.react';

const PaymentDetails = ({ paymentMethodGroup1, paymentDetails }) => {
    if (paymentMethodGroup1 === 'visa') {
        return (
            <div className="credit-card-inputs">
                <InputText placeholder="Card Number" style={{ width: '100%' }} />
                <InputText placeholder="Expiration Month" style={{ width: '100%' }} />
                <InputText placeholder="Expiration Year" style={{ width: '100%' }} />
                <InputText placeholder="CVC" style={{ width: '100%' }} />
            </div>
        );
    } else if (paymentMethodGroup1 === 'payCash') {
        return (
            <div className="bank-details">
                <p>{paymentDetails.details.bankName}</p>
                <p>Number: {paymentDetails.details.accountNumber}</p>
                <p>IBAN: {paymentDetails.details.iban}</p>
                <p>SWIFT: {paymentDetails.details.swift}</p>
            </div>
        );
    } else if (paymentMethodGroup1 === 'qrCode') {
        return (
            <div className="qr-code">
                <QRCodeCanvas value={paymentDetails.details.accountNumber} style={{ display: 'block', margin: '0 auto' }} />
                <p>{paymentDetails.details.bankName}</p>
                <p>Number: {paymentDetails.details.accountNumber}</p>
                <p>IBAN: {paymentDetails.details.iban}</p>
                <p>SWIFT: {paymentDetails.details.swift}</p>
            </div>
        );
    }
    return null;
};

export default PaymentDetails;
