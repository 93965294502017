// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/pages/Invoice.js

import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Sidebar from './Sidebar';
import Header from '../components/Header';
import PropTypes from 'prop-types';
import '../css/InvoiceComponent.scss';
import { API_URLS } from '../api/api';  // Import API URLs
import { Button } from 'primereact/button';  // Import PrimeReact Button


const AdminInvoice = forwardRef((props, ref) => {

    const [language] = useState('EN');
    const [translations, setTranslations] = useState({});
    const [fixedRate, setFixedRate] = useState({});
    const [storeConstants, setStoreConstants] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [bookingDetailsExtended, setBookingDetailsExtended] = useState([]);
    const [loading, setLoading] = useState(false);  // Loading state for the button
    const [totalAmount, setTotalAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const invoiceRef = useRef();
    // Update state initialization
    const [clientDetails, setClientDetails] = useState({});

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,  // Use the forwarded ref
        documentTitle: 'Invoice',
        onBeforeGetContent: () => setLoading(true),  // Show loading before content generation
        onAfterPrint: () => setLoading(false),  // Stop loading after print
    });



    // Inside the useEffect to fetch branch details, similar to Receipt.js
    useEffect(() => {
        const fetchBranchDetails = async () => {
            try {
                // Retrieve user_ID from localStorage
                let userID = localStorage.getItem('user_ID');
                if (!userID) {
                    const data = await fetchSomeUserAPI(); // Fetch user_ID if not available
                    userID = data.user;
                    localStorage.setItem('user_ID', userID);
                }

                const branchResponse = await fetch(API_URLS.BRANCH_DETAILS(userID));  // Fetch branch details using userID
                if (!branchResponse.ok) {
                    throw new Error(`Failed to fetch branch details with status: ${branchResponse.status}`);
                }

                const branchData = await branchResponse.json();
                if (branchData.length > 0) {
                    const branchInfo = branchData[0];
                    setStoreConstants({
                        name: branchInfo.branch_company,
                        address: branchInfo.location,
                        notes: branchInfo.branch_name,
                        phone: branchInfo.phone,
                        email: branchInfo.branch_email,
                        img: branchInfo.branch_img
                    });
                }
            } catch (error) {
                console.error("Fetching branch details failed:", error);
            }
        };

        fetchBranchDetails();
    }, []);



    const t = translations[language] || {};

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const translationsResp = await fetch(API_URLS.TRANSLATIONS);
                const translationsData = await translationsResp.json();
                setTranslations(translationsData);

                const fixedRateResp = await fetch(API_URLS.FIXED_RATE);
                const fixedRateData = await fixedRateResp.json();
                //console.log("Fixed Rate Data:", fixedRateData); // Inspect fixedRateData
                setFixedRate(fixedRateData);

                const storeConstantsResp = await fetch(API_URLS.STORE_CONSTANTS);
                const storeConstantsData = await storeConstantsResp.json();
                setStoreConstants(storeConstantsData);
            } catch (error) {
                console.error("Fetching config data failed:", error);
            }
        };
        fetchConfigData();
    }, []);


    useEffect(() => {
        const fetchInvoices = async () => {
            const bookingID = localStorage.getItem('localBookingReduxID');
            const token = localStorage.getItem('token');

            if (!bookingID) {
                alert('ไม่พบ Booking ID ในระบบ');
                return;
            }

            try {
                const bookingResponse = await fetch(API_URLS.BOOKING_DETAILS(bookingID), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                // Log the raw booking response to verify the API call result
                //console.log('Booking Response:', bookingResponse);

                if (!bookingResponse.ok) {
                    throw new Error(`HTTP error! status: ${bookingResponse.status}`);
                }

                const bookingData = await bookingResponse.json();
                //console.log('Booking Data:', bookingData);

                // ตรวจสอบว่าเป็น array และไม่ว่าง
                if (Array.isArray(bookingData) && bookingData.length > 0) {
                    setBookingDetails(bookingData[0]);

                    const fetchedTotalAmount = parseFloat(bookingData[0].TotalAmount);
                    const fetchedAmountPaid = parseFloat(bookingData[0].AmountPaid);

                    setTotalAmount(fetchedTotalAmount);
                    setAmountPaid(fetchedAmountPaid);

                    const customerID = bookingData[0].CustomerID;
                    const customerResponse = await fetch(API_URLS.CUSTOMER_DETAILS(customerID), {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    if (!customerResponse.ok) {
                        throw new Error(`HTTP error! status: ${customerResponse.status}`);
                    }

                    const customerData = await customerResponse.json();
                    setCustomerDetails(customerData);

                    // ดึงรายละเอียดเพิ่มเติมของการจอง
                    const detailResponse = await fetch(API_URLS.BOOKING_DETAILS_EXTENDED(bookingID));
                    if (!detailResponse.ok) {
                        throw new Error(`Failed to fetch booking details with status: ${detailResponse.status}`);
                    }
                    const bookingDetailsJson = await detailResponse.json();

                    // Log the detailed booking data to inspect its content
                    console.log('Booking Details Extended (Parsed):', bookingDetailsJson);

                    const extendedDetails = await Promise.all(bookingDetailsJson.map(async (detail) => {
                        const priceResponse = await fetch(API_URLS.SERVICE_PRICE(detail.ServiceID));

                        // Log the raw price response for debugging
                        console.log(`Raw priceResponse for ServiceID ${detail.ServiceID}:`, priceResponse);


                        if (!priceResponse.ok) {
                            throw new Error(`Failed to fetch price details for ServiceID ${detail.ServiceID}`);
                        }

                        const priceJson = await priceResponse.json();
                        console.log(`Price details for ServiceID ${detail.ServiceID}:`, priceJson); // Log service price details

                        // Ensure priceJson.results exists and has at least one element
                        if (Array.isArray(priceJson.results) && priceJson.results.length > 0) {
                            return {
                                ...detail,
                                ServiceName: priceJson.results[0].ServiceName || 'N/A',
                                PriceType: priceJson.results[0].PriceType || 'N/A',
                                PriceValue: priceJson.results[0].PriceValue || 'N/A',
                                CurrencySymbol: priceJson.results[0].symbol || 'N/A'
                            };
                        } else {
                            // Handle case where priceJson.results is undefined or empty
                            return {
                                ...detail,
                                ServiceName: 'N/A',
                                PriceType: 'N/A',
                                PriceValue: 'N/A',
                                CurrencySymbol: 'N/A'
                            };
                        }
                    }));


                    setBookingDetailsExtended(extendedDetails);
                } else {
                    alert('Booking information not found');
                }
            } catch (error) {
                console.error("Fetching invoices failed:", error);
            }
        };

        fetchInvoices();
    }, []);



    // Define the formatDate function
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };


    // if (!invoices || !invoices.length) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboard-main">
                <Header />

                <Button
                    label="Download PDF"
                    icon="pi pi-file-pdf"
                    className="print-button"
                    loading={loading}
                    onClick={handlePrint}
                />

                <div className="content-area">
                    <div ref={invoiceRef} className="invoice-container">
                        {/* Header Section */}
                        <header className="invoice-header">
                            {/* Company Logo */}
                            <img src={storeConstants?.img || 'default-logo.png'} alt="Company Logo"  className="invoice-logo" />
                            {/* Invoice Title */}
                            <h1>{t['INVOICE']}</h1> {/* Use the translated invoice title */}
                        </header>

                        {/* Client Details Section */}
                        <section className="client-details">
                            {/* Client Information */}

                            <div className='text-left'>
                                <strong>{t['INVOICE_TO']}</strong>
                                <p>{customerDetails?.first_name} {customerDetails?.sur_name}</p>

                                <div className='line-index'></div>

                                <strong>{t['CONTACT_PERSON']}</strong>

                                <p className='bottom-unset'>{t['PHONE']}: {customerDetails?.telephone}</p>
                                <p className='bottom-unset'>{t['EMAIL']}: {customerDetails?.email}</p>
                                <p>{t['ADDRESS']}: {customerDetails?.contact}</p>

                            </div>

                            {/* Invoice Information */}
                            <div className='text-left'>
                                <p className='bottom-unset'>{t['INVOICE_NO']}: {bookingDetails?.BookingID}</p>
                                <p>{t['INVOICE_DATE']}: {formatDate(bookingDetails?.date)}</p>

                                <div className='line-index'></div>
                                <strong> {t['PAYMENT_METHOD']} </strong>
                                <p className='bottom-unset'>{t['ORDER_ID']}: {bookingDetails?.BookingID}</p>
                                <p className='bottom-unset'>{t['ACCOUNT_NAME']}: {customerDetails?.first_name} {customerDetails?.sur_name} </p>
                                <p> {t['STATUS']}: {bookingDetails?.PaymentStatusName}</p>

                            </div>
                        </section>

                        {/* Invoice Items Section */}
                        <table className="invoice-items">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t['DESCRIPTION']}</th>
                                    <th>{t['QUANTITY']}</th>
                                    <th>{t['SERVICE']}</th>
                                    <th>{t['PRICE']}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {bookingDetailsExtended.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ServiceName || 'N/A'}</td>
                                        <td>{`${new Date(item.ServiceDate).toLocaleDateString()} ${item.ServiceTime}`}</td>
                                        <td>{item.PriceType || 'N/A'}</td>
                                        <td>{item.BookingDetailPrice ? `${item.BookingDetailPrice} ${item.CurrencySymbol}` : 'N/A'}</td>

                                    </tr>
                                ))}
                            </tbody>




                        </table>

                        <div className="row mt-4">

                        <div className="col-6">
                                <img src={storeConstants?.img} alt="invoice Logo" className="footer-logo" />
                                <p className='bottom-unset'>{storeConstants?.name}</p> {/* Removed clientDetails.name fallback */}
                                <p>{storeConstants?.address}</p> {/* Removed clientDetails.address fallback */}
                                <div className='line-index'></div>
                                <p>{storeConstants?.notes}</p> {/* Removed clientDetails.notes fallback */}
                            </div>

                            <div className="col-3">
                                <p className="grid-item sub-total-label">{t['SUB_TOTAL']}</p>
                                <p className="grid-item sub-total-label">{t['TAX']} ({fixedRate.tax}%)</p>
                                <p className="grid-item sub-total-label">{t['TOTAL_TRA']} </p>
                            </div>

                            <div className="col-3">
                                {/* <p className='grid-item'>{totalAmount.toFixed(2)}</p> */}
                                {/* <p className='grid-item'>{totalAmount.toFixed(2) - ((totalAmount * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p> */}

                                <p className='grid-item'>{totalAmount.toFixed(2)}</p>

                                <p className='grid-item'>{((amountPaid * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p>

                                <p className='grid-item'>{amountPaid.toFixed(2)}</p>
                            </div>
                        </div>

                        <div className='line-index'></div>
                        <div className='mt-2' >
                            <div className="legal-notes text-left">
                                <strong >{t['NOTES_IMPORTANTES']} :</strong>
                                <p> {t['NOTES_IMPORTANT']}</p>
                            </div>

                            <div className="row mt-4">
                                <div className="col-5">
                                    <p className="icon-text">
                                        <i className="pi pi-map-marker large-icon"></i>
                                        <span>{storeConstants.address}</span>
                                        <strong>{t['STORE_ADDRESS']}</strong>
                                    </p>
                                </div>

                                <div className="col-3">
                                    <p className="icon-text">
                                        <i className="pi pi-phone large-icon"></i>
                                        <span>{storeConstants.phone}</span>
                                    </p>
                                </div>

                                <div className="col-4">
                                    <p className="icon-text">
                                        <i className="pi pi-envelope large-icon"></i>
                                        <span>{storeConstants.email}</span>
                                    </p>
                                </div>
                            </div>

                            <div className='line-index'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});



// Prop types validation
AdminInvoice.propTypes = {

    language: PropTypes.string, // Assuming language is a string prop
};

export default AdminInvoice;
