/// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/ImageForm.js

import React from 'react';

const ImageForm = ({ selectedImage, previewImage, handleImageChange }) => {
  return (
    <div className="mb-3">
      <label htmlFor="serviceImageInput" className="form-label">Cover Image:</label>
      <input
        type="file"
        className="form-control"
        id="serviceImageInput"
        onChange={handleImageChange}
      />
      {previewImage && (
        <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
      )}
    </div>
  );
};

export default ImageForm;
