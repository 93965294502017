/// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/ReviewsForm.js

import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { API_URLS, API_ENDPOINTS } from '../api/api';  // Import API_ENDPOINTS

// Add these import statements at the top of your file
import { Editor } from 'primereact/editor';
import { Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap for the Grid system


// Define the header template separately
const headerTemplate = (
  <span className="ql-formats">
    <button className="ql-bold" aria-label="Bold"></button>
    <button className="ql-italic" aria-label="Italic"></button>
    <button className="ql-underline" aria-label="Underline"></button>
  </span>
);

const ReviewsForm = ({ reviewData, mode, onCancel, onSave, categoryOptions = [], user_ID }) => {

  const [formData, setFormData] = useState({
    CommentName: reviewData?.comment || '',  // Updated to CommentName
    topic_title: reviewData?.topic_title || '',  // Added topic_title
    rating: reviewData?.rating || '',
    status: reviewData?.status || '',
    review_typeID: reviewData?.review_typeID || null,
    CategoryID: reviewData?.CategoryID || '',
    ServiceName: reviewData?.ServiceName || '',
  });

  const [reviewTypeOptions, setReviewTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]); // State for status options

  // Common headers for API calls
  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };


  // Function to fetch Review Types
  const fetchReviewTypes = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.SERVICES_VIEW_TYPE, {
        method: 'GET',
        headers: commonHeaders,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch review types');
      }
      const data = await response.json();
      const formattedOptions = data.map(option => ({
        label: option.ServiceTypes_Name,
        value: option.ServiceTypes_ID,
      }));
      setReviewTypeOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching review types:', error);
    }
  };

  // Function to fetch Status Options
  const fetchStatusOptions = async () => {
    try {
      const response = await fetch(API_URLS.STATUS_LIST, {
        method: 'GET',
        headers: commonHeaders,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch status options');
      }
      const data = await response.json();
      const formattedStatusOptions = data.map(status => ({
        label: status.name,  // Correct label
        value: status.id,    // Correct value
      }));
      setStatusOptions(formattedStatusOptions);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };



  // Fetch review types and status options
  useEffect(() => {
    if (user_ID) {
      const fetchData = async () => {
        await fetchReviewTypes();
        await fetchStatusOptions();
      };
      fetchData();
    }
  }, [user_ID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // New handler for comment changes, avoiding conflicts
  const handleCommentChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      CommentName: e.htmlValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent auto-refresh
    onSave(formData);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="reviews-form-container mt-4 mb-4">
        {/* Topic (InputText) */}

        <Row className="reviews-form-row justify-content-center">
          <Col xs={12} md={6}>
            <div className="reviews-form-field">
              <label htmlFor="topic_title">Topic Title:</label>
              <InputText
                id="topic_title"
                name="topic_title"  // Name matches the formData key
                value={formData.topic_title}  // Bind to topic_title
                onChange={handleChange}
                required
              />
            </div>
          </Col>
        </Row>


        {/* Review Type, Rating, and Status in one row */}
        <Row className="reviews-form-row justify-content-center">
          <Col xs={12} md={4}>
            <div className="reviews-form-field">
              <label htmlFor="review_typeID">Review Type:</label>
              <Dropdown
                id="review_typeID"
                name="review_typeID"
                value={formData.review_typeID}
                options={reviewTypeOptions}
                onChange={(e) => setFormData({ ...formData, review_typeID: e.value })}
                placeholder="Select a Review Type"
                required
              />
            </div>
          </Col>

          <Col xs={12} md={4} className="rating-container">
            <div className="reviews-form-field">
              <label htmlFor="rating" className="rating-label">Rating:</label>
              <Rating
                value={formData.rating}
                onChange={(e) => setFormData({ ...formData, rating: e.value })}
                cancel={false}
              />
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="reviews-form-field">
              <label htmlFor="status">Status:</label>
              <Dropdown
                id="status"
                name="status"
                value={formData.status}
                options={statusOptions}
                onChange={(e) => setFormData({ ...formData, status: e.value })}
                placeholder="Select a Status"
                required
              />
            </div>
          </Col>
        </Row>

        {/* Comment Editor */}
        <Row className="reviews-form-row justify-content-center">
          <Col xs={12} md={10}>
            <div className="reviews-form-field">
              <label htmlFor="CommentName">Comment:</label>
              <Editor
                id="CommentName"
                name="CommentName"
                value={formData.CommentName || ''}  // Updated to match formData key
                onTextChange={handleCommentChange}
                headerTemplate={headerTemplate}
                style={{ height: '200px' }}
              />
            </div>
          </Col>
        </Row>

        {/* Save and Cancel Buttons */}
        <Row className="reviews-form-buttons justify-content-center">
          <Col xs={12} md={6}>
            <Button label="Save" icon="pi pi-check" type="submit" className="p-button-success" />
            <Button label="Cancel" icon="pi pi-times" type="button" onClick={onCancel} className="p-button-secondary" style={{ marginLeft: '2%' }} />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ReviewsForm;
