// /var/www/website2024/admin.siamweeds.com/myapp/src/components/DataQueues/EventDialogServiceTimeSidebar/PaymentLabels.js

import React from 'react';

const PaymentLabels = () => {
  return (
    <>
      <div className="p-field-horizontal">
        <label htmlFor="visa" className="payment-label">Visa</label>
      </div>
      <div className="p-field-horizontal">
        <label htmlFor="payCash" className="payment-label">Pay Cash</label>
      </div>
      <div className="p-field-horizontal">
        <label htmlFor="qrCode" className="payment-label">QR-Code</label>
      </div>
    </>
  );
};

export default PaymentLabels;
