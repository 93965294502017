// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/pages/Login.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Login.scss'; // อัปเดตเพื่อนำเข้าไฟล์ SCSS ของคุณ
import { loginUser, fetchCRMSystemInfo } from '../api/api'; // Import the login function

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [crmSystemInfo, setCrmSystemInfo] = useState(null); // State to hold CRM system info

  useEffect(() => {
    const getCRMInfo = async () => {
      try {
        const selectedCRM = await fetchCRMSystemInfo(); // Fetch CRM system info directly
        setCrmSystemInfo(selectedCRM); // Set fetched data to state
      } catch (error) {
        console.error('Error fetching CRM system info:', error);
      }
    };

    getCRMInfo(); // Fetch the CRM info on component mount

    const token = localStorage.getItem('token');
    if (token) {
      navigate('/Dashboard');
    }
  }, [navigate]);

  if (!crmSystemInfo) {
    return <div>Loading...</div>; // Show loading state while CRM info is being fetched
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message
    setShowModal(false); // Hide modal on new login attempt

    if (!email || !password) {
      setErrorMessage('Please enter both email and password.');
      setShowModal(true);
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setShowModal(true);
      return;
    }

    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      setShowModal(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const data = await loginUser(email, password); // Use the login function

      if (data.message !== 'OK') {
        setErrorMessage('Login failed: Incorrect email or password.');
        setShowModal(true); // Show modal on login failure
        setIsSubmitting(false);
        return;
      }

      localStorage.setItem('token', data.token);
      localStorage.setItem('user_ID', data.user);

      navigate('/Dashboard');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred.');
      setShowModal(true); // Show modal on fetch error
      setIsSubmitting(false);
    }
  };

  return (
    <div 
    className="background-container" 
    style={{ backgroundImage: `url(${crmSystemInfo["background-image"]})` }} // Dynamically apply background image
  >
      <div className="background-overlay"></div>
      <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>


        <div className="card background-login shadow-lg p-4 mb-5 bg-white rounded" style={{ maxWidth: '400px' }}>
          <div className="card-body">

            <h3 className="text-center mb-4">{crmSystemInfo["name-system"]}</h3>

            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label htmlFor="staticEmail" className="form-label">Enter Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="staticEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputPassword" className="form-label">Enter Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="inputPassword"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                  <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                    <i className={showPassword ? "fa-solid fa-eye-slash" : "fa-regular fa-eye"}></i>
                  </button>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <><i className="fas fa-spinner fa-spin"></i> Logging in...</>
                  ) : (
                    'LOGIN'
                  )}
                </button>
              </div>
            </form>

            <p className="text-center mt-4 footer-text">
              <a href={crmSystemInfo["developer-url"]} target="_blank" rel="noopener noreferrer">
                {crmSystemInfo["developer-info"]}
              </a>
            </p>

          </div>
        </div>

        {/* Bootstrap Modal for Error Message */}
        {showModal && (
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Login Error</h5>
                  <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                </div>
                <div className="modal-body">
                  <p>{errorMessage}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Add this div to create a backdrop effect when modal is displayed */}
        {showModal && <div className="modal-backdrop show"></div>}

      </div>
    </div>

  );
}

export default Login;
