///var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/components/ReviewsTable.js

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { API_URLS } from '../api/api'; // Assuming API_URLS contains STATUS_LIST
import { Rating } from 'primereact/rating';  // Import the Rating component

const ReviewsTable = ({ reviewsData, onEditReview, onDeleteReview }) => {
  
  const [statusOptions, setStatusOptions] = useState({});  // To store status options from API

  // Fetch status options from the API
  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const response = await fetch(API_URLS.STATUS_LIST, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        const statusMap = {};
        data.forEach(status => {
          statusMap[status.id] = status.name;  // Create a map of id to name
        });
        setStatusOptions(statusMap);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);  // Fetch once on component mount

  // Updated statusBodyTemplate to use the fetched status options
  const statusBodyTemplate = (rowData) => {
    return statusOptions[rowData.status] || 'Unknown';  // Default to 'Unknown' if status not found
  };

  // Template for action buttons
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-primary p-button-sm"
          onClick={() => onEditReview(rowData.review_id)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-button-sm"
          onClick={() => onDeleteReview(rowData.review_id)}
        />
      </React.Fragment>
    );
  };

   // Template for displaying rating using Rating component
   const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };


  return (
    <div className="table-responsive">
      <DataTable value={reviewsData} paginator rows={10} responsiveLayout="scroll">
        <Column field="review_id" header="ID"></Column>
        <Column field="topic_title" header="Topic"></Column>
        <Column field="rating" header="Rating" body={ratingBodyTemplate}></Column>
        <Column field="status" header="Status" body={statusBodyTemplate}></Column>
        <Column header="Actions" body={actionBodyTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default ReviewsTable;
