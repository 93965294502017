  // /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/TypeservicesTable.js
  import React, { useEffect, useRef, useState } from 'react';
  import $ from 'jquery';

  import 'datatables.net-bs5';
  import 'datatables.net-responsive-bs5'; // Import responsive extension for DataTables

  // Import FontAwesome CSS if not already imported
  import '@fortawesome/fontawesome-free/css/all.min.css';
  import '../css/DataTableStyleAfaa.css'; // นำเข้าไฟล์ CSS ที่กำหนดเอง

  const TypeservicesTable = ({ typeServicesData, searchQuery, onEdit, onDelete }) => {
    const dataTableRef = useRef(null);
    const [pageLength, setPageLength] = useState(10); // ตั้งค่าเริ่มต้น

    useEffect(() => {
      const dataTable = $(dataTableRef.current).DataTable({
        data: typeServicesData,
        responsive: true,
        pageLength,
        columns: [

          {
            title: 'No.',
            data: null,
            defaultContent: '',
            orderable: false,
            searchable: false,
            responsivePriority: 1,
            render: (data, type, row, meta) => meta.row + meta.settings._iDisplayStart + 1
          },
          {
            title: 'ServiceTypes ID',
            data: 'ServiceTypes_ID',
            responsivePriority: 2,
            width: '20%', // You can set a percentage or a fixed width like '100px'
          },

          { title: 'Name', data: 'ServiceTypes_Name', responsivePriority: 3 },
         
          {
            title: 'Description',
            data: 'Description',
            width: '15%',
            responsivePriority: 4,
            render: (data) => {
              return data.length > 30 ? data.substring(0, 30) + '...' : data;
            }
          },

          
          { title: 'StatusName', data: 'StatusName', responsivePriority: 5 },
          {
            title: 'Actions',
            data: null,
            width: '15%',
            render: (data) => {
              return `<i class="fas fa-pen-to-square edit-icon" data-id="${data.ServiceTypes_ID}"></i>
                      <i class="fas fa-trash delete-icon" data-id="${data.ServiceTypes_ID}"></i>`;
            },
          },

        ],


        ordering: true,
        dom: "<'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>", // Updated DOM positioning
        pagingType: "full_numbers",
        language: {
          paginate: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        initComplete: function () {
          this.api().on('draw', function () {
            const pagination = $('.dataTables_paginate');
            pagination.addClass('pagination-dark justify-content-end');
            pagination.find('a').addClass('page-link');
            pagination.find('span').addClass('page-item');
          });
        },
      });


      // Attach event listeners
      $(dataTableRef.current).on('click', '.edit-icon', function () {
        const data = dataTable.row($(this).parents('tr')).data();
        onEdit(data);
      });

      // Page length change handler
      document.getElementById('pageLengthSelect').addEventListener('change', (e) => {
        const newPageLength = parseInt(e.target.value, 10);
        setPageLength(newPageLength);
        dataTable.page.len(newPageLength).draw();
      });

      // Attach event listener for delete icon
      const deleteIconHandler = (event) => {
        const serviceTypeId = parseInt($(event.currentTarget).data('id'), 10);
        onDelete(serviceTypeId); // Call with single ID
      };

      $(dataTableRef.current).on('click', '.delete-icon', deleteIconHandler);


      // Search query change handler

      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable().search(searchQuery).draw();
      }



      // Cleanup function to remove event listener
      return () => {
        // Cleanup
        if ($.fn.dataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
      };
    }, [typeServicesData, onEdit, onDelete, pageLength]); // Existing dependencies

    // Search query change handler
    useEffect(() => {
      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable().search(searchQuery).draw();
      }
    }, [searchQuery]); // This will re-run whenever searchQuery changes




    return (
      <div className="">
        <div className="table-responsive">
          <table ref={dataTableRef} className="table custom-datatable"></table>
        </div>
      </div>
    );
  };

  export default TypeservicesTable;
