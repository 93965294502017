// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/redux/slices/sidebarSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const { setSidebarVisible } = sidebarSlice.actions;

export const selectSidebarVisible = (state) => state.sidebar.visible;

export default sidebarSlice.reducer;
