// /var/www/website2024/admin.siamweeds.com/myapp/src/components/reports/BookingReport.js

import React, { useEffect, useState,forwardRef, useImperativeHandle } from 'react';
import { Row, Col } from 'react-bootstrap';
import ChartComponent from '../Chart';
import Filters from './FiltersBookingReport';
import { fetchMockData, fetchEmployees, fetchCustomers, statuses, types, fetchBranches, fetchServices } from '../../data/mockDataBookingReport';
import { API_URLS } from '../../api/api';


const hours = Array.from({ length: 24 }, (_, i) => ({ label: `${i}:00`, value: `${i}:00` }));

const allOption = { label: 'All Data', value: null };

const BookingReport = forwardRef((props, ref) => {

  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [services, setServices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [dateRangeData, setDateRangeData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const [currencySymbol, setCurrencySymbol] = useState('฿'); // Default symbol

  const [filters, setFilters] = useState({
    employee: null,
    customer: null,
    BranchID: '1',
    status: null,
    service: null,
    type: null,
    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    startTime: null,
    endTime: null
  });

  const [timeFilters, setTimeFilters] = useState({
    oneHour: false,
    twelveHours: false,
    twentyFourHours: false,
    sevenDays: false,
    thirtyDays: true,
    threeMonths: false,
    twelveMonths: false,
    customDate: false,
    customTime: false,
  });

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await fetch(API_URLS.CURRENCIES);
        const data = await response.json();

        // Set default currency symbol to Thai Baht (THB) as per your requirement
        const thaiBahtCurrency = data.currencies.find(currency => currency.symbol === 'THB');
        if (thaiBahtCurrency) {
          setCurrencySymbol(thaiBahtCurrency.currency_symbol);
        }
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    fetchCurrencies();
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchMockData(filters);
      setData(result);
    };

    const initializeData = async () => {
      const branchesData = await fetchBranches();
      const servicesData = await fetchServices();
      const employeesData = await fetchEmployees();
      const customersData = await fetchCustomers();

      setBranches(branchesData);
      setServices(servicesData);
      setEmployees([allOption, ...employeesData]);
      setCustomers([allOption, ...customersData]);
    };

    fetchData();
    initializeData();
  }, [filters]);

  useEffect(() => {
    if (data.length === 0) {
      setDateRangeData([]);
      setStatusData([]);
      setTypeData([]);
      setServiceData([]);
      return;
    }

    const bookingsByDateRange = data.map(booking => ({ x: new Date(booking.ServiceDate), y: booking.PriceValue }))
      .reduce((acc, curr) => {
        const existing = acc.find(item => item.x.toDateString() === curr.x.toDateString());
        if (existing) {
          existing.y += curr.y;
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);

    const bookingsByStatus = data.reduce((acc, booking) => {
      const existing = acc.find(item => item.label === booking.StatusName);
      if (existing) {
        existing.y += 1;
      } else {
        acc.push({ label: booking.StatusName, y: 1 });
      }
      return acc;
    }, []).map(item => ({
      ...item,
      y: Math.round(item.y)
    }));

    console.log("Data for Status:", bookingsByStatus); // เพิ่ม console.log ตรงนี้
    

    const totalValue = data.reduce((sum, booking) => sum + booking.PriceValue, 0);
    const bookingsByType = data.reduce((acc, booking) => {
      const existing = acc.find(item => item.label === booking.BookingType);
      if (existing) {
        existing.y += booking.PriceValue;
        existing.priceValue += booking.PriceValue;
      } else {
        acc.push({ label: booking.BookingType, y: booking.PriceValue, priceValue: booking.PriceValue });
      }
      return acc;
    }, []).map(item => ({
      ...item,
      y: (item.y / totalValue * 100).toFixed(2),
      priceValue: item.priceValue
    }));

    console.log("Data for Type:", bookingsByType); // เพิ่ม console.log ตรงนี้

    const bookingsByService = data.reduce((acc, booking) => {
      const existing = acc.find(item => item.label === booking.ServiceName);
      if (existing) {
        existing.y += booking.PriceValue;
      } else {
        acc.push({ label: booking.ServiceName, y: booking.PriceValue });
      }
      return acc;
    }, []).filter(item => item.y > 1);

    setDateRangeData(bookingsByDateRange);
    setStatusData(bookingsByStatus);
    setTypeData(bookingsByType);
    setServiceData(bookingsByService);

    console.log("Data set in BookingReport:", {
      bookingsByDateRange,
      bookingsByStatus,
      bookingsByType,
      bookingsByService
    });
  }, [data]);

// Expose clearFilters function via ref using useImperativeHandle
useImperativeHandle(ref, () => ({
  handleClearFilters() {
    clearFilters();
  }
}));

  const clearFilters = () => {
    setFilters({
      employee: null,
      customer: null,
      BranchID: '1',
      status: null,
      service: null,
      type: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null
    });

    setTimeFilters({
      oneHour: false,
      twelveHours: false,
      twentyFourHours: false,
      sevenDays: false,
      thirtyDays: true,
      threeMonths: false,
      twelveMonths: false,
      customDate: false,
      customTime: false,
    });
  };


  const branchesOptions = [allOption, ...branches];
  const servicesOptions = [allOption, ...services];
  const statusesOptions = [allOption, ...statuses];
  const typesOptions = [allOption, ...types];

  const handleBranchChange = (selectedOption) => {
    console.log('Selected Branch:', selectedOption);
    setFilters(prevFilters => ({
      ...prevFilters,
      BranchID: selectedOption.value
    }));
  };

  return (
    <div className="p-datatable-details">
    <div className="content">
     
      <Filters
        filters={filters}
        setFilters={setFilters}
        timeFilters={timeFilters}
        setTimeFilters={setTimeFilters}
        hours={hours}
        employeesOptions={employees}
        customersOptions={customers}
        branchesOptions={branchesOptions}
        statusesOptions={statusesOptions}
        servicesOptions={servicesOptions}
        typesOptions={typesOptions}
        clearFilters={clearFilters}
        onBranchChange={handleBranchChange}
        setData={setData}
      />
      <Row>
        <Col xs={12} md={6} className="chart-col">
          <h6>Date Range</h6>

          <ChartComponent
            data={dateRangeData}
            title="Date Range"
            chartType="column"
            xTitle="Date"
            yTitle="Total Value"
            yPrefix={currencySymbol}
            showCurrency={true}
            tooltipOptions={{
              callbacks: {
                label: (tooltipItem) => {
                  return `${currencySymbol} ${tooltipItem.raw.y.toFixed(2)}`; // Use the correct currency symbol
                }
              }
            }}
          />


        </Col>

        <Col xs={12} md={6} className="chart-col">
          <h6>Service</h6>

          <ChartComponent
            data={serviceData}
            title="Service"
            chartType="column"
            xTitle="Service Name"
            yTitle="Total Booking Value"
            yPrefix={currencySymbol}
            showCurrency={true}
            tooltipOptions={{
              callbacks: {
                label: (tooltipItem) => {
                  return `${currencySymbol} ${tooltipItem.raw.y.toFixed(2)}`;
                }
              }
            }}
          />


        </Col>

      </Row>

      <Row>
        
        <Col xs={12} md={6} className="chart-col">
          <h6> Type </h6>
          <ChartComponent data={typeData} title="Type" chartType="pie" xTitle="Booking Type" yTitle="Percentage" showCurrency={false} />
        </Col>


        <Col xs={12} md={6} className="chart-col">
          <h6>Status</h6>
          <ChartComponent data={statusData} title="Status" chartType="bar" xTitle="Booking Status" yTitle="Number of Bookings" showCurrency={false} />
        </Col>
        </Row>
    </div>
    </div>
  );
});
export default BookingReport;